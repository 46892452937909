@import "./src/styles/helpers/_index.scss";
.Prestatus {
  &Container {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &Item {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;

    color: #000;

    border-radius: 50%;

    margin-right: 5px;

    &Good {
      background-color: #3fba53;
    }

    &Norm {
      background-color: #ffb521;
    }

    &Badly {
      background-color: #f4424b;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

.table {
  &.centered-cells {
    td, th {
      vertical-align: middle;
      text-align: center;
    }

    tr {
      height: 49px;
    }
  }
  &.table-selectable {
    tbody tr {
      cursor: pointer;
    }
  }
}

.small-single-selectize {
  + .selectize-control {
    padding-top: 1px;
    height: 32px;
    .selectize-input {
      text-align: left;
      height: 18px;
      padding: 3px 30px 7px 12px;
      border-radius: 3px !important;
      min-height: 30px;
    }
  }
}

body.app-ad .selectize-control.single .selectize-input > div {
  padding-left: 0;

  border-color: transparent !important;
}
