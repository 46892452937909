@import "./src/styles/helpers/_index.scss";
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
// Variables, Mixins
@import "helpers/index";
@import "base/bootstrap";
// INSPINIA Theme Elements
@import "base/typography";
@import "components/navigation";
@import "layout/top_navigation";
@import "components/buttons";
@import "components/badgets_labels";
@import "elements";
@import "layout/sidebar";
@import "base/base";
@import "pages/pages";
@import "components/metismenu";
@import "components/react-tabs";
@import "components/spinners";
// Landing page styles
@import "landing";
// INSPINIA Skins
@import "skins";
@import "md-skin";
// Media query style
@import "media";
//font-awesome icons
@import "./css/font-awesome.css";
//notifications
@import "./css/angular-notify.css";
// alert
@import "./css/sweetalert.css";
// switcher
@import "./css/switchery.css";

@import "~react-bootstrap-modal/lib/css/rbm-patch.css";
@import "~icheck/skins/square/green.css";
@import "~react-s-alert/dist/s-alert-default.css";
@import "~react-s-alert/dist/s-alert-css-effects/slide.css";
@import "~react-tabs/style/react-tabs.css";
@import "~rc-tooltip/assets/bootstrap.css";
@import "~jexcel/dist/jexcel.css";

.page {
  height: 100%;
}

// Clear layout on print mode
@media print {
  body {
    overflow: visible !important;
  }
}

@import "base/base.custom";
@import "components/c-calendar";
@import "components/c-checkbox-label";
@import "components/c-disablable-block";
@import "components/c-nav-profile";
@import "components/c-no-ui-slider";
@import "components/selectize";
@import "components/smart-table";
@import "components/other";
@import "components/tooltipster";
@import "components/tooltip";
@import "components/react-select";
@import "components/jexcel";
@import "components/alert";
@import "md-skin.fix";
@import "global-spinner";
