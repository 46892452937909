@import "./src/styles/helpers/_index.scss";
.RoleLabel {
  font-weight: normal;

  input[type='radio'] {
    float: left;

    margin-top: 2px;
    margin-right: 5px;
  }
}
