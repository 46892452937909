@import "./src/styles/helpers/_index.scss";
$size: 120;               // px
$smallSize: 14;           // px
$textFontSize: 16;        // px
$time: 2;                 // seconds
$count: 12;

.Loader {
  visibility: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  opacity: 0;

  transition: all 0.2s ease;

  z-index: 100;

  &Absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &WithBackground {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &Active {
    visibility: visible;

    opacity: 1;
  }
  &Content {
    width: $size + px;
    height: $size + px;

    margin: 0 auto;

    text-align: center;
  }
  &Animation {
    list-style: none;

    position: absolute;
    left: 50%;
    top: 50%;

    width: $size + px;
    height: $size + px;

    margin: 0;

    transform: translateX(-50%) translateY(-50%);

    animation: rotatePreloader $time + s infinite ease-in;

    @keyframes rotatePreloader {
      0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
      }
      100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
      }
    }

    &Item {
      position: absolute;

      width: 100%;
      height: 100%;

      opacity: 0;

      &:before {
        content: '';

        position: absolute;
        left: 50%;
        top: 0;

        width: 12%;
        height: 12%;

        background-color: #f48e28;

        transform: translateX(-50%);

        border-radius: 50%;
      }
      @for $i from 1 through $count {
        &:nth-child(#{$i}) {
          transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
          animation: rotateCircle + $i $time + s infinite linear;
          z-index: $count - $i;
        }

        @keyframes rotateCircle#{$i} {
          #{percentage(((50 / $count) * ($i - 1)) / 100)} {
            opacity: 0;
          }
          #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
            opacity: 1;
            transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
          }
          #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
          }
          #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
          }
          100% {
            transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
            opacity: 1;
          }
        }
      }
    }
  }
  &Text {
    margin: 0;
    padding: #{$size / 2 - $textFontSize}px 0;

    font-size: $textFontSize + px;
    font-weight: 400;
    color: #fff;
    text-shadow: 1px 1px 2px #003b49;
  }
  &Description {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    margin: #{$size / 2 + 20}px 0 0;

    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 2px #001f2b;
  }
  &Small &Content,
  &Small &Animation {
    width: $smallSize + px;
    height: $smallSize + px;
  }
}
