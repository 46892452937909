@import "./src/styles/helpers/_index.scss";
.multi-select {
  .dropdown-heading {
    border: none !important;
    height: 35px !important;
    border-radius: 0;
  }

  .dropdown-content {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgb(0, 0, 0) rgb(204, 204, 204) rgb(204, 204, 204) !important;
    border-top: none !important;
    max-height: 170px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}

.select-panel .select-item {
  padding: 0 !important;
  margin: 0 !important;
  background-color: inherit !important;
}
