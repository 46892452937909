@import "./src/styles/helpers/_index.scss";
.collapsible-cell {
  .clamp-lines__button {
    padding: 0;
    border: 0;
    background: none;
    color: #337ab7;
    cursor: pointer;

    &:hover {
      color: #23527c;
    }
  }
}
