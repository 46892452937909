@import "./src/styles/helpers/_index.scss";
.changelog-modal {
  .modal-dialog {
    width: fit-content;
    min-width: 60%;
  }

  .table-hover {
    th, td {
      max-width: 400px;
      padding: 20px;
      word-break: break-word;
      white-space: pre-line;
    }
  }
}
