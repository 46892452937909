@import "./src/styles/helpers/_index.scss";
.js-switch {
  display: inline-block;
  pointer-events: none;
  cursor: pointer;
}

td {
  &._disabled {
    font-size: 20px;
    color: red;
  }
}

th {
  &.actions {
    width: 250px
  }
}
