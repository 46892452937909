@import "./src/styles/helpers/_index.scss";
.react-tabs__tab-list {
  border-bottom: 1px solid #d0d0d0 !important;
  margin: 0 0 0px !important;
  padding: 0 !important;
}

.react-tabs__tab--selected {
  color: #676a6c !important;
  border-color: #d0d0d0 !important;
}

.flowchart-errors-alert .swal-footer {
  text-align: center;
}

.flowchart-save-modal .modal-body {
  padding: 20px 30px 10px 30px;
  background-color: #F8FAFB;
}

.flowchart-save-modal .modal-header {
  padding: 25px;
  text-align: center;
}

.planBy {
  display: flex;
  align-items: center;
  justify-content: center;

  &-label {
    padding-top: 0 !important;
  }

  &-item {
    display: inline-block;
    position: relative;

    & label {
      margin: 0 !important;
      padding-left: 15px;
    }

    & input {
      position: absolute;
      top: -1px;
    }
  }
}

.success-balance {
  background-color: rgb(212, 237, 218) !important;
}

.error-balance {
  background-color: rgba(239, 79, 43, 0.7) !important;

  &-text {
    color: #fff;
  }
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: #999;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
.placeholder {
  background: #f8f8f8;
  height: 200px;
  margin: 10px 0;
  padding-top: 80px;
}

.budgets-switcher {
  &_container {
    position: relative;
  }

  &_item {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    & .switchery {
      width: 40px;
      height: 20px;
    }

    & .switchery > small {
      width: 20px;
      height: 20px;
    }
  }
}

.button-fixed {
  position: fixed;
  bottom: 15px;
  right: 25px;
}

.lock-info {
  position: fixed;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
  z-index: 10;

  right: 40px;
  top: 80px;

  background: #e5dede;
  padding: 10px;
}


.lock-info__unlock {
  margin-top: 20px;
}
