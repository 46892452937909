.jexcel {
  td {
    padding: 10px;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
  }

  &__loading {
    padding: 50px 20px;

    font-size: 16px;
    text-align: center;
  }

  &__row {
    &_color_gray {
      background-color: darken($gray, 30%);
      color: white;
      font-weight: 700;
      border-bottom: 1px solid #ddd;
    }
  }

  &_container &__loading {
    display: none;
  }
}
