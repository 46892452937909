// Basic Colors
$navy: #1ab394; // Primary color
$dark-gray: #c2c2c2; // Default color
$blue: #1c84c6; // Success color
$lazur: #23c6c8; // Info color
$yellow: #f8ac59; // Warrning color
$red: #ED5565; // Danger color

// Various colors
$text-color: #676a6c; // Body text
$gray: #f3f3f4; // Background wrapper color
$light-gray: #D1DADE; // Default label, badget
$label-badget-color: #5E5E5E;
$light-blue: #f3f6fb;
$link-color: #33828e;


// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;


// IBOX colors ( default panel colors)
$border-color: #e7eaec; // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url('/img/patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profil-pattern: url("/img/patterns/header-profile.png");
$nav-text-color: #a7b1c2;

$color-active: rgba(26, 179, 148, 0.3);
$color-blue: #0d83c9;
$color-green: #1ab394;
$color-pink: #fce4cd;
$color-orange: #faa732;
$color-red: $red;
$color-prime: $color-green;
$color-offprime: $color-blue;
$color-book-success: $color-orange;
$color-book-selected: #c66a1c;
$color-book-failure: $color-red;
$color-holiday: #fdd8d5;
$color-alert: #de6768;
