body.app-ad .selectize-control.single .selectize-input > div {
  padding-left: 0;

  border-color: transparent !important;
}

.form-control[date-range-picker][readonly] {
  background-color: #ffffff;
}

.table {
  &.centered-cells {
    td, th {
      vertical-align: middle;
      text-align: center;
    }
  }

  &.table-selectable {
    tbody tr {
      cursor: pointer;
    }
  }
}

.display-flex {
  display: flex;
}

.position-relative {
  position: relative;
}

.right-flex-table {
  overflow-x: auto;
  flex-grow: 1;
}

.flowCharts-budgets-fixed-table {
  td {
    padding-top: 14px !important;
    padding-bottom: 14px !important;

    background-color: #f5f5f5;
  }
}

.flowCharts-budget-table {
  th, td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  td {
    background-color: #f5f5f5;
  }
}

.flowCharts-tvc-title {
  span {
    display: inline-block;

    margin-top: 15px;
    padding-top: 15px;

    border-top: 1px solid #e7eaec;

    font-size: 13px;
  }
}

.flowCharts-media-control {
  margin-top: 20px;
}

.flowCharts-media-fixed-table {
  th, td {
    height: 36px;

    padding-left: 6px !important;
    padding-right: 6px !important;

    border: 1px solid #e7eaec;

    text-align: center;
  }

  th {
    height: 108px;

    background-color: #f5f5f5;
  }

  td {
    &.vertical-align--middle {
      vertical-align: middle !important;
    }

    &.with-percent-postfix {
      &:after {
        content: '%';
      }
    }

    &.with-rub-postfix {
      &:after {
        content: 'p.';
      }
    }
  }
}

.flowCharts-media-months-table {
  th, td {
    min-width: 50px;
    height: 36px;

    padding-left: 6px !important;
    padding-right: 6px !important;

    border: 1px solid #e7eaec;

    text-align: center;

    &:first-child {
      border-left: none;
    }
  }

  th {
    background-color: #f5f5f5;
  }

  td {
    &.input-cell {
      width: 63px;

      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    &.input-budget-cell {
      width: 63px;

      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    &.with-rub-postfix {
      &:after {
        content: 'p.';
      }
    }

    &.with-empty-spaces {
      &:before,
      &:after {
        content: '\00a0 ';
      }
    }
  }

  &-field {
    width: 50px;
    height: 24px;

    padding: 4px 1px;

    text-align: center;

    &--full-width {
      width: 100%;
    }
    [disabled] {
      background-color: #eee;
    }
  }
  &-cell-with-locker {
    position: relative;

    &-icon {
      position: absolute;
      top: -8px;
      right: -6px;

      padding: 3px;

      transform: scale(-1, 1);
    }
  }
}

.flowCharts-empty-tvc-list h5 {
  float: none;

  display: block;

  padding-top: 30px;
  padding-bottom: 30px;

  text-align: center;
}

.flowCharts-delete-button-container {
  float: right;

  margin-top: -2px;
}

.flowCharts-confirm-button-container {
  padding-top: 8px;
}

.flowCharts-spinner-container {
  position: relative;

  .sk-loader,
  .sk-spinner {
    position: absolute;
    top: 6px;
    right: 50px;
  }

  .sk-loader {
    top: 50%;
    right: 60px;

    transform: translate3d(0, -50%, 0);
  }
}

.Pretest {
  &good {
    background-color: #B8F1C9;
  }

  &normal {
    background-color: #FFEEB5;
  }

  &badly {
    background-color: #FABEC1;
  }
}

.fake-disabled {
  background-color: #eee;
}

.regionality-list {
  &-container-main {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    z-index: 0;

    &-active {
      z-index: 1;
    }
  }

  .flowCharts-media-fixed-table &-cell {
    padding: 0 !important;
  }

  &-container-list {
    position: absolute;
    background: #fff;
    width: 100%;
    top: 100%;

    display: none;

    &-active {
      display: block;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    max-height: 175px;
    overflow: hidden;
    overflow-y: auto;

    &-item {
      padding-top: 9px;
      padding-bottom: 8px;
      position: relative;

      transition: background-color 0.175s ease-out;

      &:first-child {
        border-top: 1px solid #e7eaec;
      }

      &:hover {
        background-color: #e7eaec;
      }

      &-region-name {
        padding-left: 25%;
        padding-right: 10px;

        overflow: hidden;

        text-align: left;
        word-wrap: break-word;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &-checkbox {
    &-container {
      position: absolute;
      left: 5%;
      top: 25%;

      cursor: pointer;
    }

    &-item {
      width: 20px;
      height: 20px;

      background-color: #D6D6D6;

      &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate3d(-50%, -50%, 0);

        width: 70%;
        height: 70%;

        background-color: #fff;

        transition: background-color 0.5s ease;
      }

      &-active:before {
        background-color: #F7BB00;
      }
    }
  }

  &-container-cell {
    padding-top: 9px;
    padding-bottom: 8px;
    padding-right: 30px;
    padding-left: 20px;

    cursor: pointer;

    max-width: 199px;
    overflow: hidden;

    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      right: 17px;
      margin-top: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #333333 transparent transparent transparent;
    }
  }

  &-container-main-active &-container-cell:after {
    margin-top: -4px;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #333333 transparent;
  }

  &-region-name {
  }
}

.campaign-checkbox {
  &-form {
    position: relative;

    @media (min-width: 1200px) {
      &-container {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
    @media (max-width: 1199px) {
      &-container {
        margin-left: 30px;
      }
    }
  }

  &-container {
    padding-left: 6px;
    padding-top: 5px;
  }

  &-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);

    height: 15px;
    width: 15px;
    border: 2px solid #676a6c;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &-container:hover input ~ &-checkmark {
    background-color: #ccc;
  }

  &-container input:checked ~ &-checkmark:after {
    display: block;
  }

  &-container &-checkmark:after {
    left: 1px;
    top: 1px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #676a6c;
  }
}

.media-type-not-implemented {
  text-align: center;
  font-size: 22px;
}

@media (min-width: 1200px) {
  .center-label {
    display: flex;

    .control-label {
      padding-top: 0;
      margin: auto;
    }
  }
}

