@import "./src/styles/helpers/_index.scss";
.Container {
  width: 220px;
  height: 100%;

  background-color: $nav-bg;

  z-index: 1;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-auto-flow: row;
  align-content: flex-start;

  &Closed {
    width: 70px;
  }
}

.Header {
  padding: 12px 20px;

  background-image: $nav-profil-pattern;

  .ContainerClosed & {
    padding: 15px 10px;
  }
}

.Logo {
  width: 100%;
}

.SkLink {
  margin: 16px;
  align-self: flex-end;
}

.SkLogo {
  width: 100%;
  height: auto;
}

.List {
  list-style: none;
}

.SubList {
  list-style: none;
  overflow: hidden;

  transition: height 0.2s ease;

  &Hidden {
    height: 0 !important;

    .ContainerClosed & {
      height: auto !important;
    }
  }
}

.Item {
  display: block;

  position: relative;
}

.Link {
  overflow: hidden;
  display: block;

  padding: 14px 20px 14px 25px;

  border-left: 4px solid transparent;

  font-weight: 600;
  color: #a7b1c2;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &Arrow {
    float: right;

    line-height: 1.42857;

    .ContainerClosed & {
      display: none;
    }
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: #293846;

    color: #fff;
    text-decoration: none;
  }
  &Active {
    border-left-color: #19aa8d;
    background-color: #293846;

    color: #fff;
  }
  &Active.LinkSubList {
    border-left-color: transparent;
    background-color: transparent;
  }
  &Active &Arrow {
    &:before {
      content: '\F107' !important;
    }
  }
  &Icon {
    width: 15px;

    margin-right: 6px;

    .ContainerClosed & {
      margin-right: 0;
    }
  }
}

.ContainerClosed .Link {
  padding: 14px 20px 14px 15px;

  font-size: 16px;
  text-align: center;
}

.ContainerClosed .LinkText {
  display: none;
}

.ContainerClosed .ItemSubList > .Link {
  padding: 14px 20px 14px 15px;
}

.ContainerClosed .SubList .LinkText {
  display: inline;
}

.ItemSubList {
  padding: 0;
}

.ItemSubList > .Link {
  padding: 14px 20px 14px 25px;

  cursor: pointer;
}

.SubList .Link {
  padding: 7px 10px 7px 52px;

  &:hover,
  &:focus {
    outline: none;
  }
  .ContainerClosed & {
    padding-left: 15px;
  }
}

.ContainerClosed .SubList {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;

  padding: 10px;

  background-color: $nav-bg;

  .Link {
    padding: 10px 10px 10px 15px;

    border-left: 0;

    font-size: 12px;
    text-align: left;

    border-radius: 3px;

    &:hover,
    &:focus {
      background-color: #293846;

      color: white;
    }
    &Icon {
      margin-right: 6px;
    }
  }
}

.ContainerClosed .ItemSubList:focus .SubList,
.ContainerClosed .ItemSubList:hover .SubList {
  display: block;
}
